@import 'variables';

/* Breakpoint mixin -- mobile first (min-width) */
@mixin breakpoint($bp, $rule: min-width) {
  @media all and (#{$rule}: map-get($breakpoints, $bp)) {
    @content;
  }
}

@mixin portrait() {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape() {
  @media (orientation: landscape) {
    @content;
  }
}

/* Unstyled default list */
@mixin unlist() {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
