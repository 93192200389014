/* DEFAULT */
$baseline: 1.25;
$primary_font: Roboto, "Helvetica Neue", sans-serif;
$caviar_font: CaviarDreams, sans-serif;
$caviar_font_bold: CaviarDreamsBold, sans-serif;
$default-button-radius: 40px;
$default-button-padding: 8px 16px;

/* FONT WEIGHT */
$thin: 100;
$light: 300;
$regular: 400;
$light-bold: 500;
$bold: 700;
$black: 900;

/* FONT SIZE */
$font-xs: 0.7rem;
$font-sm: 0.8rem;
$font-default: 1rem; // regular - 16px
$font-md: 1.125rem; // large - 18px
$font-lg: 1.25rem; // h3 - 20px
$font-xlg: 1.5625rem; // h2 - 25px
$font-xxlg: 1.875rem; // h1 - 30px

/* BREAKPOINTS */
$breakpoints: (
  xsmall: 480px,
  small: 736px,
  medium: 980px,
  large: 1280px,
  xlarge: 1620px,
  xxlarge: 1690px
);

/* TEMP COLORS */
$pink: #E23749;
$orange: #E95C30;
$purple: #6D2B48;
$blue: #232148;
$gray: #EEEEEF;
$white: #FFFFFF;
$black: #000000;