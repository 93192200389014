@import "index";
@import "theme";

html {
  font-family: $primary_font;
  font-size: $font-default;
  line-height: $baseline;
}

html,
body {
  position: fixed;

  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;

  * {
    box-sizing: border-box;
  }
}

body {
  @include body-large-regular;
  font-family: $primary_font;
}

.mat-typography {
  h1 {
    @include heading1;
  }

  h2 {
    @include heading2;
  }

  h3 {
    @include heading3;
  }

  p {
    @include body-medium-regular;
  }
}

.grecaptcha-badge {
  bottom: 130px !important;
}

.layout {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@include portrait() {
  .layout {
    &::before {
      background-image: url('../assets/backgrounds/coming-soon-portrait.svg');
    }
  }
}

@include landscape() {
  .layout {
    &::before {
      background-image: url('../assets/backgrounds/coming-soon-landskape.svg');
    }
  }
}
