@import "core/variables";

::ng-deep .mat-radio-button {
  .mat-radio-label {
    white-space: pre-wrap;
  }

  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    border-radius: 5px;
    border-width: 1px;
  }

  .mat-radio-inner-circle {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 26px;
    height: 26px;
  }
}

::ng-deep .mat-checkbox-layout {
  white-space: pre-wrap;

  .mat-checkbox-inner-container,
  .mat-checkbox-frame {
    height: 25px;
    width: 25px;
  }

  .mat-checkbox-frame,
  .mat-checkbox-background {
    border-radius: 5px;
    border-width: 1px;
  }
}

.mat-form-field-required-marker {
  color: $pink;
}
