@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@font-face {
  font-family: 'CaviarDreams';
  src: url('../../assets/fonts/caviardreams_regular/CaviarDreams-webfont.eot');
  src: url('../../assets/fonts/caviardreams_regular/CaviarDreams-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/caviardreams_regular/CaviarDreams-webfont.woff') format('woff'),
  url('../../assets/fonts/caviardreams_regular/CaviarDreams-webfont.ttf') format('truetype'),
  url('../../assets/fonts/caviardreams_regular/CaviarDreams-webfont.svg#caviar_dreamsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CaviarDreamsBold';
  src: url('../../assets/fonts/caviardreams_bold/Caviar_Dreams_Bold-webfont.eot');
  src: url('../../assets/fonts/caviardreams_bold/Caviar_Dreams_Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/caviardreams_bold/Caviar_Dreams_Bold-webfont.woff') format('woff'),
  url('../../assets/fonts/caviardreams_bold/Caviar_Dreams_Bold-webfont.ttf') format('truetype'),
  url('../../assets/fonts/caviardreams_bold/Caviar_Dreams_Bold-webfont.svg#caviar_dreamsbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@mixin heading1() {
  font-style: normal;
  font-size: $font-xxlg;
  font-weight: $bold;
  line-height: 42.19px;
}

@mixin heading2() {
  font-style: normal;
  font-size: $font-xlg;
  font-weight: $bold;
  line-height: 35.16px;
}

@mixin heading3() {
  font-style: normal;
  font-size: $font-lg;
  font-weight: $bold;
  line-height: 28.13px;
}

@mixin body-large-semi-bold() {
  font-style: normal;
  font-size: $font-md;
  font-weight: $light-bold;
  line-height: 25.31px;
}

@mixin body-large-regular() {
  font-style: normal;
  font-size: $font-md;
  font-weight: $regular;
  line-height: 25.31px;
}

@mixin body-large-light() {
  font-style: normal;
  font-size: $font-md;
  font-weight: $light;
  line-height: 25.31px;
}

@mixin body-medium-semi-bold() {
  font-style: normal;
  font-size: $font-default;
  font-weight: $light-bold;
  line-height: 22.5px;
}

@mixin body-medium-regular() {
  font-style: normal;
  font-size: $font-default;
  font-weight: $regular;
  line-height: 22.5px;
}
